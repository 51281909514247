$cAccent: #3978EF;
$cBlack: #121212;
$cGray: #333;
$cLightGray: rgba(#000, 0.1);
.py-3,
.pt-3,
.p-3 {
    padding-top: 1rem !important;
}
.p-1 {
    padding: .25rem !important;
}
.py-3,
.pb-3,
.p-3 {
    padding-bottom: 1rem !important;
}
.p-3, .pr-3 {
    padding-right: 1rem !important;
}
.p-3, .pl-3 {
    padding-left: 1rem !important;
}
.mt-3,
.my-3 {
    margin-top: 1rem !important;
}

.mr-2 {
    margin-right: 0.5rem;
}
.mb-3 {
    margin-bottom: 1rem !important;
}
.ml-2, label > i {
    margin-left: 0.5rem;
}
.container {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
}

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.row {
    display: -webkit-box;
    display: flex;
    flex-wrap: wrap;
    margin-right: -0.75rem;
    margin-left: -0.75rem;
}

.col-xl,
.col-xl-auto,
.col-xl-12,
.col-xl-11,
.col-xl-10,
.col-xl-9,
.col-xl-8,
.col-xl-7,
.col-xl-6,
.col-xl-5,
.col-xl-4,
.col-xl-3,
.col-xl-2,
.col-xl-1,
.col-lg,
.col-lg-auto,
.col-lg-12,
.col-lg-11,
.col-lg-10,
.col-lg-9,
.col-lg-8,
.col-lg-7,
.col-lg-6,
.col-lg-5,
.col-lg-4,
.col-lg-3,
.col-lg-2,
.col-lg-1,
.col-md,
.col-md-auto,
.col-md-12,
.col-md-11,
.col-md-10,
.col-md-9,
.col-md-8,
.col-md-7,
.col-md-6,
.col-md-5,
.col-md-4,
.col-md-3,
.col-md-2,
.col-md-1,
.col-sm,
.col-sm-auto,
.col-sm-12,
.col-sm-11,
.col-sm-10,
.col-sm-9,
.col-sm-8,
.col-sm-7,
.col-sm-6,
.col-sm-5,
.col-sm-4,
.col-sm-3,
.col-sm-2,
.col-sm-1,
.col,
.col-auto,
.col-12,
.col-11,
.col-10,
.col-9,
.col-8,
.col-7,
.col-6,
.col-5,
.col-4,
.col-3,
.col-2,
.col-1 {
    position: relative;
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
}

.col-auto {
    -webkit-box-flex: 0;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
}

.col-1 {
    -webkit-box-flex: 0;
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
}

.col-2 {
    -webkit-box-flex: 0;
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
}

.col-3 {
    -webkit-box-flex: 0;
    flex: 0 0 25%;
    max-width: 25%;
}

.col-4 {
    -webkit-box-flex: 0;
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
}

.col-5 {
    -webkit-box-flex: 0;
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
}

.col-6 {
    -webkit-box-flex: 0;
    flex: 0 0 50%;
    max-width: 50%;
}

.col-7 {
    -webkit-box-flex: 0;
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
}

.col-8 {
    -webkit-box-flex: 0;
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
}

.col-9 {
    -webkit-box-flex: 0;
    flex: 0 0 75%;
    max-width: 75%;
}

.col-10 {
    -webkit-box-flex: 0;
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
}

.col-11 {
    -webkit-box-flex: 0;
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
}

.col-12 {
    -webkit-box-flex: 0;
    flex: 0 0 100%;
    max-width: 100%;
}

.col-form-label {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
}

.col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5;
}

.col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.875rem;
    line-height: 1.5;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    width: 100%;
    padding-right: 0.75rem;
    padding-left: 0.75rem;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container-sm,
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container-md,
    .container-sm,
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container-xl,
    .container-lg,
    .container-md,
    .container-sm,
    .container {
        max-width: 1140px;
    }
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-sm-1 > * {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-sm-2 > * {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-sm-3 > * {
        -webkit-box-flex: 0;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-sm-4 > * {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-sm-5 > * {
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-sm-6 > * {
        -webkit-box-flex: 0;
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-sm-auto {
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-sm-1 {
        -webkit-box-flex: 0;
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-sm-2 {
        -webkit-box-flex: 0;
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-sm-3 {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-sm-4 {
        -webkit-box-flex: 0;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-sm-5 {
        -webkit-box-flex: 0;
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-sm-6 {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-sm-7 {
        -webkit-box-flex: 0;
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-sm-8 {
        -webkit-box-flex: 0;
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-sm-9 {
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-sm-10 {
        -webkit-box-flex: 0;
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-sm-11 {
        -webkit-box-flex: 0;
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-sm-12 {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-sm-first {
        -webkit-box-ordinal-group: 0;
        order: -1;
    }

    .order-sm-last {
        -webkit-box-ordinal-group: 14;
        order: 13;
    }

    .order-sm-0 {
        -webkit-box-ordinal-group: 1;
        order: 0;
    }

    .order-sm-1 {
        -webkit-box-ordinal-group: 2;
        order: 1;
    }

    .order-sm-2 {
        -webkit-box-ordinal-group: 3;
        order: 2;
    }

    .order-sm-3 {
        -webkit-box-ordinal-group: 4;
        order: 3;
    }

    .order-sm-4 {
        -webkit-box-ordinal-group: 5;
        order: 4;
    }

    .order-sm-5 {
        -webkit-box-ordinal-group: 6;
        order: 5;
    }

    .order-sm-6 {
        -webkit-box-ordinal-group: 7;
        order: 6;
    }

    .order-sm-7 {
        -webkit-box-ordinal-group: 8;
        order: 7;
    }

    .order-sm-8 {
        -webkit-box-ordinal-group: 9;
        order: 8;
    }

    .order-sm-9 {
        -webkit-box-ordinal-group: 10;
        order: 9;
    }

    .order-sm-10 {
        -webkit-box-ordinal-group: 11;
        order: 10;
    }

    .order-sm-11 {
        -webkit-box-ordinal-group: 12;
        order: 11;
    }

    .order-sm-12 {
        -webkit-box-ordinal-group: 13;
        order: 12;
    }

    .offset-sm-0 {
        margin-left: 0;
    }

    .offset-sm-1 {
        margin-left: 8.3333333333%;
    }

    .offset-sm-2 {
        margin-left: 16.6666666667%;
    }

    .offset-sm-3 {
        margin-left: 25%;
    }

    .offset-sm-4 {
        margin-left: 33.3333333333%;
    }

    .offset-sm-5 {
        margin-left: 41.6666666667%;
    }

    .offset-sm-6 {
        margin-left: 50%;
    }

    .offset-sm-7 {
        margin-left: 58.3333333333%;
    }

    .offset-sm-8 {
        margin-left: 66.6666666667%;
    }

    .offset-sm-9 {
        margin-left: 75%;
    }

    .offset-sm-10 {
        margin-left: 83.3333333333%;
    }

    .offset-sm-11 {
        margin-left: 91.6666666667%;
    }
}

@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-md-1 > * {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-md-2 > * {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-md-3 > * {
        -webkit-box-flex: 0;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-md-4 > * {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-md-5 > * {
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-md-6 > * {
        -webkit-box-flex: 0;
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-md-auto {
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-md-1 {
        -webkit-box-flex: 0;
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-md-2 {
        -webkit-box-flex: 0;
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-md-3 {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-md-4 {
        -webkit-box-flex: 0;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-md-5 {
        -webkit-box-flex: 0;
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-md-6 {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-md-7 {
        -webkit-box-flex: 0;
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-md-8 {
        -webkit-box-flex: 0;
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-md-9 {
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-md-10 {
        -webkit-box-flex: 0;
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-md-11 {
        -webkit-box-flex: 0;
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-md-12 {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-md-first {
        -webkit-box-ordinal-group: 0;
        order: -1;
    }

    .order-md-last {
        -webkit-box-ordinal-group: 14;
        order: 13;
    }

    .order-md-0 {
        -webkit-box-ordinal-group: 1;
        order: 0;
    }

    .order-md-1 {
        -webkit-box-ordinal-group: 2;
        order: 1;
    }

    .order-md-2 {
        -webkit-box-ordinal-group: 3;
        order: 2;
    }

    .order-md-3 {
        -webkit-box-ordinal-group: 4;
        order: 3;
    }

    .order-md-4 {
        -webkit-box-ordinal-group: 5;
        order: 4;
    }

    .order-md-5 {
        -webkit-box-ordinal-group: 6;
        order: 5;
    }

    .order-md-6 {
        -webkit-box-ordinal-group: 7;
        order: 6;
    }

    .order-md-7 {
        -webkit-box-ordinal-group: 8;
        order: 7;
    }

    .order-md-8 {
        -webkit-box-ordinal-group: 9;
        order: 8;
    }

    .order-md-9 {
        -webkit-box-ordinal-group: 10;
        order: 9;
    }

    .order-md-10 {
        -webkit-box-ordinal-group: 11;
        order: 10;
    }

    .order-md-11 {
        -webkit-box-ordinal-group: 12;
        order: 11;
    }

    .order-md-12 {
        -webkit-box-ordinal-group: 13;
        order: 12;
    }

    .offset-md-0 {
        margin-left: 0;
    }

    .offset-md-1 {
        margin-left: 8.3333333333%;
    }

    .offset-md-2 {
        margin-left: 16.6666666667%;
    }

    .offset-md-3 {
        margin-left: 25%;
    }

    .offset-md-4 {
        margin-left: 33.3333333333%;
    }

    .offset-md-5 {
        margin-left: 41.6666666667%;
    }

    .offset-md-6 {
        margin-left: 50%;
    }

    .offset-md-7 {
        margin-left: 58.3333333333%;
    }

    .offset-md-8 {
        margin-left: 66.6666666667%;
    }

    .offset-md-9 {
        margin-left: 75%;
    }

    .offset-md-10 {
        margin-left: 83.3333333333%;
    }

    .offset-md-11 {
        margin-left: 91.6666666667%;
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-lg-1 > * {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-lg-2 > * {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-lg-3 > * {
        -webkit-box-flex: 0;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-lg-4 > * {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-lg-5 > * {
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-lg-6 > * {
        -webkit-box-flex: 0;
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-lg-auto {
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-lg-1 {
        -webkit-box-flex: 0;
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-lg-2 {
        -webkit-box-flex: 0;
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-lg-3 {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-lg-4 {
        -webkit-box-flex: 0;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-lg-5 {
        -webkit-box-flex: 0;
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-lg-6 {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-lg-7 {
        -webkit-box-flex: 0;
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-lg-8 {
        -webkit-box-flex: 0;
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-lg-9 {
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-lg-10 {
        -webkit-box-flex: 0;
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-lg-11 {
        -webkit-box-flex: 0;
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-lg-12 {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-lg-first {
        -webkit-box-ordinal-group: 0;
        order: -1;
    }

    .order-lg-last {
        -webkit-box-ordinal-group: 14;
        order: 13;
    }

    .order-lg-0 {
        -webkit-box-ordinal-group: 1;
        order: 0;
    }

    .order-lg-1 {
        -webkit-box-ordinal-group: 2;
        order: 1;
    }

    .order-lg-2 {
        -webkit-box-ordinal-group: 3;
        order: 2;
    }

    .order-lg-3 {
        -webkit-box-ordinal-group: 4;
        order: 3;
    }

    .order-lg-4 {
        -webkit-box-ordinal-group: 5;
        order: 4;
    }

    .order-lg-5 {
        -webkit-box-ordinal-group: 6;
        order: 5;
    }

    .order-lg-6 {
        -webkit-box-ordinal-group: 7;
        order: 6;
    }

    .order-lg-7 {
        -webkit-box-ordinal-group: 8;
        order: 7;
    }

    .order-lg-8 {
        -webkit-box-ordinal-group: 9;
        order: 8;
    }

    .order-lg-9 {
        -webkit-box-ordinal-group: 10;
        order: 9;
    }

    .order-lg-10 {
        -webkit-box-ordinal-group: 11;
        order: 10;
    }

    .order-lg-11 {
        -webkit-box-ordinal-group: 12;
        order: 11;
    }

    .order-lg-12 {
        -webkit-box-ordinal-group: 13;
        order: 12;
    }

    .offset-lg-0 {
        margin-left: 0;
    }

    .offset-lg-1 {
        margin-left: 8.3333333333%;
    }

    .offset-lg-2 {
        margin-left: 16.6666666667%;
    }

    .offset-lg-3 {
        margin-left: 25%;
    }

    .offset-lg-4 {
        margin-left: 33.3333333333%;
    }

    .offset-lg-5 {
        margin-left: 41.6666666667%;
    }

    .offset-lg-6 {
        margin-left: 50%;
    }

    .offset-lg-7 {
        margin-left: 58.3333333333%;
    }

    .offset-lg-8 {
        margin-left: 66.6666666667%;
    }

    .offset-lg-9 {
        margin-left: 75%;
    }

    .offset-lg-10 {
        margin-left: 83.3333333333%;
    }

    .offset-lg-11 {
        margin-left: 91.6666666667%;
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex-basis: 0;
        -webkit-box-flex: 1;
        flex-grow: 1;
        max-width: 100%;
    }

    .row-cols-xl-1 > * {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .row-cols-xl-2 > * {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .row-cols-xl-3 > * {
        -webkit-box-flex: 0;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .row-cols-xl-4 > * {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .row-cols-xl-5 > * {
        -webkit-box-flex: 0;
        flex: 0 0 20%;
        max-width: 20%;
    }

    .row-cols-xl-6 > * {
        -webkit-box-flex: 0;
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-xl-auto {
        -webkit-box-flex: 0;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
    }

    .col-xl-1 {
        -webkit-box-flex: 0;
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
    }

    .col-xl-2 {
        -webkit-box-flex: 0;
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
    }

    .col-xl-3 {
        -webkit-box-flex: 0;
        flex: 0 0 25%;
        max-width: 25%;
    }

    .col-xl-4 {
        -webkit-box-flex: 0;
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%;
    }

    .col-xl-5 {
        -webkit-box-flex: 0;
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%;
    }

    .col-xl-6 {
        -webkit-box-flex: 0;
        flex: 0 0 50%;
        max-width: 50%;
    }

    .col-xl-7 {
        -webkit-box-flex: 0;
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%;
    }

    .col-xl-8 {
        -webkit-box-flex: 0;
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%;
    }

    .col-xl-9 {
        -webkit-box-flex: 0;
        flex: 0 0 75%;
        max-width: 75%;
    }

    .col-xl-10 {
        -webkit-box-flex: 0;
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%;
    }

    .col-xl-11 {
        -webkit-box-flex: 0;
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%;
    }

    .col-xl-12 {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
    }

    .order-xl-first {
        -webkit-box-ordinal-group: 0;
        order: -1;
    }

    .order-xl-last {
        -webkit-box-ordinal-group: 14;
        order: 13;
    }

    .order-xl-0 {
        -webkit-box-ordinal-group: 1;
        order: 0;
    }

    .order-xl-1 {
        -webkit-box-ordinal-group: 2;
        order: 1;
    }

    .order-xl-2 {
        -webkit-box-ordinal-group: 3;
        order: 2;
    }

    .order-xl-3 {
        -webkit-box-ordinal-group: 4;
        order: 3;
    }

    .order-xl-4 {
        -webkit-box-ordinal-group: 5;
        order: 4;
    }

    .order-xl-5 {
        -webkit-box-ordinal-group: 6;
        order: 5;
    }

    .order-xl-6 {
        -webkit-box-ordinal-group: 7;
        order: 6;
    }

    .order-xl-7 {
        -webkit-box-ordinal-group: 8;
        order: 7;
    }

    .order-xl-8 {
        -webkit-box-ordinal-group: 9;
        order: 8;
    }

    .order-xl-9 {
        -webkit-box-ordinal-group: 10;
        order: 9;
    }

    .order-xl-10 {
        -webkit-box-ordinal-group: 11;
        order: 10;
    }

    .order-xl-11 {
        -webkit-box-ordinal-group: 12;
        order: 11;
    }

    .order-xl-12 {
        -webkit-box-ordinal-group: 13;
        order: 12;
    }

    .offset-xl-0 {
        margin-left: 0;
    }

    .offset-xl-1 {
        margin-left: 8.3333333333%;
    }

    .offset-xl-2 {
        margin-left: 16.6666666667%;
    }

    .offset-xl-3 {
        margin-left: 25%;
    }

    .offset-xl-4 {
        margin-left: 33.3333333333%;
    }

    .offset-xl-5 {
        margin-left: 41.6666666667%;
    }

    .offset-xl-6 {
        margin-left: 50%;
    }

    .offset-xl-7 {
        margin-left: 58.3333333333%;
    }

    .offset-xl-8 {
        margin-left: 66.6666666667%;
    }

    .offset-xl-9 {
        margin-left: 75%;
    }

    .offset-xl-10 {
        margin-left: 83.3333333333%;
    }

    .offset-xl-11 {
        margin-left: 91.6666666667%;
    }
}

.m-0 {
    margin: 0 !important;
}

.p-3 {
    padding: 1rem !important; 
}

.pb-3 {
    padding-bottom: 1rem !important;
}

.pt-3 {
    padding-top: 1rem !important;
}

.pb-1 {
    padding-bottom: 0.25rem !important;
}

.pt-1 {
    padding-top: 0.25rem !important;
}

.font-weight-bold {
    font-weight: bolder !important;
}
.d-none {
    display: none !important;
}

.flex {
    display: flex !important;
}


.accordion-list{
    list-style: none;
    margin: 0;
    padding: 0;
    background-color: #fff;
    max-width: 100%;
    border-radius: 0.4rem;
    overflow: hidden;
 
    
    &__item{

      &+&{
        border-top: 1px solid $cLightGray;
      }
    }
  }
  
  .accordion-item{
    $self: &;
  
    &--opened{    
      #{ $self }__icon{
        transform: rotate(180deg);
      }
          
      #{ $self }__inner{
        max-height: 100rem;
        transition-timing-function: cubic-bezier(0.895, 0.03, 0.685, 0.22);
        transition-duration: 0.5s;
        transition-property: max-height;
      }
      
      #{ $self }__content{
        opacity: 1;
        transform: translateY(0);
        transition-delay: 0.2s;
        transition-timing-function: ease-in-out;
        transition-duration: 0.2s;
        transition-property: opacity, transform;
      }
    }
  
    &__line{
      display: block;
      padding: 0.8rem 1.2rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      z-index: 2;
      position: relative;
      cursor: pointer;
    }
    
    &__title{
      font-size: 1.6rem;
      margin: 0;
      font-weight: 700;
      color: $cBlack;
      cursor: pointer;
    }
    
    &__icon{
      width: 1.2rem;
      height: 1.2rem;
      transition: transform 0.3s ease-in-out;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAQAAABIkb+zAAABGklEQVR4Ae3RAcZCQRiF4buDfwshBGi+2UQgcIGAVtpSIuS/KyilG+UTcbk6zIH3GQBm3mM6AAAAAAAAAACA+eqf/yZBXcV/2XeCVPYx1FXj/FjGUMd45AQp/1HHGGLZNL+e61jHnKDmv8652YT1IvPfE2LX/Sh27/ycsF60yT/lk58JYn6eU4MJccjnlAmZ/33i0OAH4jg9Qcw/5g9YJpS+m6n0xvzpCfVe+nn59S7kGyYo+YYJWz3fO+E2PaFs9XzPhMy/6fmWCXq+YUJs9HzrhLh+JsQmrnq+bYKeb52g53snXPR88wQ93z9Bz/dP0PP9E/R89wQ93zpBz7dO0POtE/R86wQ93zpBzzdP+MoHAAAAAAAAAADAExTnTW20AtjhAAAAAElFTkSuQmCC);
      opacity: 0.6;
    }
    
    &__inner{
      max-height: 0;
      overflow: hidden;
      text-transform: cubic-bezier(0.95, 0.05, 0.795, 0.035);
      transition-duration: 0.5s;
      transition-property: max-height;
      z-index: 1;
      position: relative;
    }
    
    &__content{
      opacity: 0;
      transform: translateY(-1rem);
      transition-timing-function: linear, ease;
      transition-duration: 0.1s;
      transition-property: opacity, transform;
      transition-delay: 0.5s;
      padding: 0 1.2rem 1.2rem;
    }
    
    &__paragraph{
      margin: 0;
      font-size: 16px;
      color: $cGray;
      font-weight: 300;
      line-height: 1.3;
    }
  }

  .mce-statusbar {
    display: none !important;
  }

  .stepp {
    border: 2px solid #e5e5e5;
    color: #ccc;
    font-size: 13px;
    border-radius: 100%;
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 24px;
    height: 24px;
    line-height: 20px;
    text-align: center;
    border-color: #800080;
    color: #800080;
  }

  .d-flex {
    display: flex !important;
  }

  .flex-center {
    justify-content: center !important;
    align-items: center !important;
  }

  .w-100 {
    width: 100% !important;
  }

  .mailing_imgCaption {
    margin-top: 10px;
    text-align: center;
    font-size: 18px;
}

.disabled {
    opacity: .5 !important;
    pointer-events: none !important;
}

.form-group.disabled > input, .form-group.disabled > select {
    background: #ddd;
}

.error {
 border-color: red !important;
}

// toast -------------------------------------------------------------------------------
#snackbar {
    visibility: hidden; /* Hidden by default. Visible on click */
    min-width: 250px; /* Set a default minimum width */
    margin-left: -125px; /* Divide value of min-width by 2 */
    background-color: #333; /* Black background color */
    color: #fff; /* White text color */
    text-align: center; /* Centered text */
    border-radius: 2px; /* Rounded borders */
    padding: 16px; /* Padding */
    position: fixed; /* Sit on top of the screen */
    z-index: 777; /* Add a z-index if needed */
    left: 50%; /* Center the snackbar */
    bottom: 30px; /* 30px from the bottom */
  }
  
  /* Show the snackbar when clicking on a button (class added with JavaScript) */
  #snackbar.show {
    visibility: visible; /* Show the snackbar */
    /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
    However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
    animation: fadein 0.5s, fadeout 0.5s 2.5s;
  }
  
  /* Animations to fade the snackbar in and out */
  @-webkit-keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  
  @keyframes fadein {
    from {bottom: 0; opacity: 0;}
    to {bottom: 30px; opacity: 1;}
  }
  
  @-webkit-keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }
  
  @keyframes fadeout {
    from {bottom: 30px; opacity: 1;}
    to {bottom: 0; opacity: 0;}
  }

.steps {
    li:not(.active) {
        cursor: pointer;
        &:hover {
            color: #aaa;
            span:first-child {
                border: 2px solid #aaa;
                color: #aaa;
            }
        }
    }
}